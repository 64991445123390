<template>
    <div class="grid content-section implementation">
        <div class="col-12">
            <div class="card">
                <div class="text-500 mb-5">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="libera-hierarquia-list" />
                       Liberação de Hierarquia
                    </div>
                </div>
                <Toast />
                <div style="align-items: center; justify-content: space-between; width: 40%; margin-bottom: 8px">
                    <Chips
                        v-model="filter"
                        placeholder="Pesquisar"
                        style="display: grid !important"
                        @add="filtrarHierarquias"
                        @remove="filtrarHierarquias"
                    />
                </div>
                <div class="p-fluid painel painel-2">
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel header="Sem Aplicação">
                            <div class="content-selection implementation">
                                <div class="grid-style">
                                    <AppGrid
                                        ref="gridSemLiberacao"
                                        :service="serviceSemAplicacao"
                                        exibirBotaoLiberacao
                                        exibirBotaoRespostas
                                        exibirBotaoPendencia
                                        @onLoad="onLoad"
                                    ></AppGrid>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Pendentes">
                            <div class="content-selection implementation">
                                <div class="grid-style">
                                    <AppGrid
                                        ref="gridPendente"
                                        :service="servicePendente"
                                        exibirBotaoLiberacao
                                        exibirPendencia
                                        exibirBotaoRespostas
                                        @onLoad="onLoad"
                                    ></AppGrid>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Concluídos">
                            <div class="content-selection implementation">
                                <div class="grid-style">
                                    <AppGrid ref="gridConcluido" :service="serviceConcluido" exibirBotaoRespostas></AppGrid>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInfoManual from '../../../components/AppInfoManual.vue';
import SecurityService from '../../../services/SecurityService';
import AppGrid from './component/AppGrid';

export default {
    components: { AppGrid, AppInfoManual },
    data() {
        return {
            activeIndex: 0,
            serviceConcluido: null,
            serviceSemAplicacao: null,
            servicePendente: null,
            filter: []
        };
    },
    created() {
        this.serviceSemAplicacao = new SecurityService('libera-hierarquia/sem-aplicacao');
        this.servicePendente = new SecurityService('libera-hierarquia/pendente');
        this.serviceConcluido = new SecurityService('libera-hierarquia/concluido');
    },
    methods: {
        onLoad() {
            this.$refs.gridPendente.load();
            this.$refs.gridConcluido.load();
        },
        async filtrarHierarquias() {
            this.loading = true;
            this.$refs.gridConcluido.filtrar(this.filter);
            this.$refs.gridPendente.filtrar(this.filter);
            this.$refs.gridSemLiberacao.filtrar(this.filter);
            this.loading = false;
        }
    }
};
</script>
