<template>
    <Dialog contentClass="pb-0" :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '40vw' }"
        header="Informações para liberação" :closable="false" :modal="true">
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="mt-3">
                <i class="pi pi-comment"></i> Descrição do Setor -> {{ liberaHierarquia.department.description ?? '' }} {{ hierarquia.descriptionDepartment ?? ''}}
            </div>
            <div class="mt-3">
                <i class="pi pi-comment"></i> Descrição do Cargo -> {{ liberaHierarquia.position.description ?? '' }}
            </div>
            <Divider align="center">
                <span class="p-tag">Respostas do questionário  de risco</span>
            </Divider>
            <div v-for="bean in respostas" :key="bean.id">
                <i class="pi pi-angle-right"></i> {{ bean.pergunta.pergunta }}
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';
import BaseService from '../../../../services/BaseService';

export default {
    props: ['liberaHierarquia'],
    data() {
        return {
            agendamentos: [],
            loading: false,
            hierarquia: null,
            respostas: [],
        }
    },
    created() {
        this.$serviceHierarchy = new BaseService('/customer/hierarchies/has-hierarchy')
        this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            await this.verificarHierarquia();
            const { data } = await getClientBase().get(`/questionarios-hierarquia/find-by-hierarchy/${this.hierarquia.id}`);
            this.respostas = data;
            this.loading = false;
        },
        async verificarHierarquia() {
            const { data } = await this.$serviceHierarchy.findAll({
                customerId: this.liberaHierarquia.customer.id,
                customerBranchId: this.liberaHierarquia.customerBranchId,
                customerDepartmentId: this.liberaHierarquia.customerDepartmentId,
                customerPositionId: this.liberaHierarquia.customerPositionId
            });
            this.hierarquia = data;
        }
    },
}
</script>
<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>