<template>
    <AppLoadingWrapper v-if="loading" />
    <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 grid-nogutter"></div>
    <DataTable v-if="!loading" :value="record" responsiveLayout="scroll">
        <template #empty> Nenhum registro encontrado. </template>
        <template #loading> Carregando registros. Aguarde ... </template>
        <Column header="Data da solicitação">
            <template #body="slotProps">
                {{ $filters.formatDateOnly(slotProps.data.agendamento.createdAt) }}
            </template>
        </Column>
        <Column header="Cliente">
            <template #body="slotProps">
                {{
                    slotProps.data.customer.code ? slotProps.data.customer.code + ' - ' + slotProps.data.customer.name : slotProps.data.customer.name
                }}
            </template>
        </Column>
        <Column header="Unidade">
            <template #body="slotProps">
                {{
                    slotProps.data.branch.codigoSoc
                        ? slotProps.data.branch.codigoSoc + ' - ' + slotProps.data.branch.name
                        : slotProps.data.branch.name
                }}
            </template>
        </Column>
        <Column header="Setor">
            <template #body="slotProps">
                {{
                    slotProps.data.department.codigoSoc
                        ? slotProps.data.department.codigoSoc + ' - ' + slotProps.data.department.name
                        : slotProps.data.department.name
                }}
            </template>
        </Column>
        <Column header="Cargo">
            <template #body="slotProps">
                {{
                    slotProps.data.position.codigoSoc
                        ? slotProps.data.position.codigoSoc + ' - ' + slotProps.data.position.name
                        : slotProps.data.position.name
                }}
            </template>
        </Column>
        <Column header="Motivo da pendência" v-if="exibirPendencia" bodyStyle="color: #D18200">
            <template #body="slotProps">
                {{ slotProps.data.observacao ? slotProps.data.observacao : 'Não Informado' }}
            </template>
        </Column>

        <slot name="columns"></slot>
        <Column bodyClass="text-right" headerStyle="width: 2rem;">
            <template #body="slotProps">
                <Button
                    v-if="exibirBotaoPendencia"
                    icon="pi pi-exclamation-triangle"
                    class="p-button p-button-text mr-0"
                    style="color: #d18200"
                    v-tooltip.bottom="'Adicionar Pendência'"
                    @click="dialogPendencia(slotProps)"
                />
                <Button
                    v-if="exibirPendencia"
                    icon="pi pi-pencil"
                    class="p-button p-button-text mr-0"
                    style="color: #d18200"
                    v-tooltip.bottom="'Editar Pendência'"
                    @click="dialogPendencia(slotProps)"
                />
            </template>
        </Column>
        <Column bodyClass="text-right" headerStyle="width: 2rem;">
            <template #body="slotProps">
                <Button
                    v-if="exibirBotaoLiberacao"
                    label="Liberar"
                    icon="pi pi-unlock"
                    class="p-button p-button-text ml-0"
                    @click="confirmLiberaHierarquia($event, slotProps)"
                />
                <div v-if="!exibirBotaoLiberacao && slotProps.data?.usuario?.urlFotoPerfil" class="user-photo-template">
                    <img
                        :src="slotProps.data.usuario.urlFotoPerfil"
                        class="user-photo"
                        height="100"
                        preview
                        v-tooltip.top="slotProps.data.usuario.name"
                    />
                </div>
                <Avatar
                    v-if="!exibirBotaoLiberacao && !slotProps.data?.usuario?.urlFotoPerfil"
                    :label="
                        slotProps.data?.usuario?.name?.split(' ')?.[1]?.[0]
                            ? slotProps.data?.usuario?.name[0] + slotProps.data?.usuario?.name?.split(' ')?.[1]?.[0]
                            : slotProps?.data?.usuario?.user?.name[0]
                    "
                    placeholder="Top"
                    v-tooltip.top="slotProps.data?.usuario?.name"
                    size="large"
                    style="background-color: #ced4da; color: #ffffff"
                    shape="circle"
                />
            </template>
        </Column>
        <Column bodyClass="text-right" headerStyle="width: 2rem;">
            <template #body="slotProps">
                <Button
                    v-if="exibirBotaoRespostas"
                    v-tooltip.bottom="'Informações para liberação'"
                    icon="pi pi-book"
                    class="p-button p-button-text ml-0"
                    @click="showRespostasDialog(slotProps)"
                />
            </template>
        </Column>
        <Column bodyClass="text-right" headerStyle="width: 1rem;">
            <template #body="slotProps">
                <Button
                    icon="pi pi-user"
                    class="p-button p-button-text mr-0"
                    v-tooltip.bottom="'Informações do agendamento'"
                    @click="dialogInfo(slotProps)"
                />
            </template>
        </Column>
    </DataTable>

    <Paginator
        :rows="perPage"
        :totalRecords="total"
        @page="onPage($event)"
        paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
    />

    <ConfirmPopup group="demo">
        <template #message="slotProps">
            <div class="flex p-4">
                <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
        </template>
    </ConfirmPopup>

    <Dialog
        v-model:visible="openDialogInfo"
        class="p-fluid"
        contentClass="pb-0"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '30vw' }"
        header="Dados da solicitação de agendamento"
        :closable="true"
        :modal="true"
        @update:visible="this.openDialogInfo = false"
    >
        <div class="pb-5 pt-5">
            <p class="header">
                Existem outras hierarquia com as mesmas informações dessa liberação.<br />
                Deseja liberá-las também?
            </p>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="this.confirmaDialg()" />
            <Button label="Sim" icon="pi pi-check" class="submit p-button-submit" @click="this.liberaHierarquiaLista()" />
        </template>
    </Dialog>
    <AppDescricaoDialog
        v-if="openDialogPendencia"
        v-model:visible="openDialogPendencia"
        title="Informe o motivo da pendência"
        @onConfirm="adicionarPendencia"
        @onClose="openDialogPendencia = false"
        :defaultDescription="recordSelecionado.observacao"
    >
    </AppDescricaoDialog>
    <RespostasDialog
        v-if="openRespostasDialog"
        v-model:visible="openRespostasDialog"
        v-model:liberaHierarquia="recordSelecionado"
        @onClose="openRespostasDialog = false"
    />
    <LiberacaoHirerquia :visible="openLiberaHierarquia" v-model:liberaHierarquia="agendamentos" @onClose="openLiberaHierarquia = false" />
</template>
<script>
import { getProfile } from '../../../../services/auth';
import SecurityService from '../../../../services/SecurityService';
import RespostasDialog from './RespostasDialog.vue';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import LiberacaoHirerquia from './LiberacaoHirerquia.vue';
import BaseService from '@/services/BaseService';

export default {
    emits: ['onLoad'],
    components: {
        RespostasDialog,
        LiberacaoHirerquia
    },
    props: {
        service: {
            type: Object,
            required: true
        },
        exibirBotaoLiberacao: {
            type: Boolean
        },
        exibirBotaoRespostas: {
            type: Boolean
        },
        exibirBotaoPendencia: {
            type: Boolean
        },
        exibirPendencia: {
            type: Boolean
        }
    },
    data() {
        return {
            record: [],
            loading: false,
            page: 1,
            total: 0,
            totalPage: 0,
            filter: '',
            perPage: 10,
            openDialogInfo: false,
            openDialogPendencia: false,
            openRespostasDialog: false,
            openLiberaHierarquia: false,
            recordSelecionado: null,
            dataConsulta: null,
            dataSolicitacao: null,
            agendamentos: []
        };
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    created() {
        this.$service = this.service;
        this.$serviceLiberarHierarquia = new SecurityService('libera-hierarquia');
        this.$serviceAddPendencia = new SecurityService('libera-hierarquia/adicionar-pendencia');
        this.$serviceBuscaHirerquia = new SecurityService('libera-hierarquia/busca-hierarquia');
        this.$serviceHierarchiaLista = new BaseService('/libera-hierarquia/liberar-lista');
        this.load();
    },
    methods: {
        async filtrar(filter) {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll(null, {
                    page: this.page,
                    limit: this.currentPerPage,
                    filter: filter
                });
                this.record = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro ao listar! ' + error, life: 3000 });
            }
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll(null, {
                    page: this.page,
                    limit: this.currentPerPage,
                    filter: this.filter
                });
                this.record = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro ao listar! ' + error, life: 3000 });
            }
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        confirmLiberaHierarquia(event, record) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'demo',
                message: 'Confirma que essa hierarquia já foi liberada no SOC?',
                icon: 'pi pi-question-circle',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    this.verificaLiberHierarquia(record.data);
                }
            });
        },
        async verificaLiberHierarquia(record) {
            this.recordSelecionado = record;
            const { data } = await this.$serviceBuscaHirerquia.findById(record.id);
            if (data?.length > 1) {
                this.agendamentos = data;
                this.openDialogInfo = true;
            } else {
                await this.liberar(record);
            }
        },

        async liberar(record) {
            console.log('record', record);
            try {
                const responsavel = await getProfile();
                record.userUpdateId = responsavel.id;
                await this.$serviceLiberarHierarquia.savePatch(record);
                this.load();
                this.$emit('onLoad');
                this.$toast.add({ severity: 'success', summary: 'Hierarquia liberada com sucesso!', life: 3000 });
            } catch (err) {
                const mensagem = err?.response?.data?.message;
                this.$toast.add({ severity: 'error', summary: 'Falha ao liberar hierarquia!', detail: mensagem, life: 5000 });
            }
        },
        async liberaHierarquiaLista() {
            const responsavel = await getProfile();
            this.openDialogInfo = false;

            const erroFuncionarios = [];

            for (const agendamento of this.agendamentos) {
                try {
                    agendamento.userUpdateId = responsavel.id;
                    await this.$serviceLiberarHierarquia.savePatch(agendamento);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: `Hierarquia ${agendamento.id} liberada com sucesso`,
                        life: 3000
                    });
                } catch (err) {
                    const mensagem = err?.response?.data?.message;

                    if (!erroFuncionarios.includes(agendamento.agendamento.funcionario.id)) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Falha ao liberar hierarquia',
                            detail: mensagem,
                            life: 5000
                        });

                        erroFuncionarios.push(agendamento.agendamento.funcionario.id);
                    }
                }
            }

            this.load();
        },

        async confirmaDialg() {
            this.openDialogInfo = false;
            const record = this.recordSelecionado;
            await this.liberar(record);
        },
        async adicionarPendencia(observacao) {
            try {
                this.recordSelecionado.observacao = observacao;
                this.openDialogPendencia = false;
                await this.$serviceAddPendencia.savePatch(this.recordSelecionado);
                this.$emit('onLoad');
                this.load();
                this.$toast.add({ severity: 'success', summary: 'Pendência adicionada com sucesso!', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Falha ao adicionar pendência', life: 3000 });
            }
        },
        async dialogInfo(record) {
            this.recordSelecionado = record.data;
            const { data } = await this.$serviceBuscaHirerquia.findById(record.data.id);
            this.openLiberaHierarquia = true;
            this.agendamentos = data;

            this.dataConsulta =
                this.$filters.formatDateOnly(this.recordSelecionado.agendamento.dataSugestaoInicial) +
                ' - ' +
                this.$filters.formatDateOnly(this.recordSelecionado.agendamento.dataSugestaoFinal);
            this.dataSolicitacao = this.$filters.formatDate(this.recordSelecionado.agendamento.createdAt);
        },
        dialogPendencia(record) {
            this.openDialogPendencia = true;
            this.recordSelecionado = record.data;
        },
        showRespostasDialog(record) {
            this.openRespostasDialog = true;
            this.recordSelecionado = record.data;
        }
    }
};
</script>

<style>
.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 39px;
    height: 39px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
